<template>
  <div>

    <h3 class="text-5xl py-8">Journey Begins In</h3>
    <CounterCards />
    <h3 class="text-x pt-6">Hold tight as we prepare for launching the most astonishing product ever.  
      Be part of the journey and get notify.</h3>
    
    <p  class="p-6 underline"><a href="https://www.digitalsmartwave.com/contact">Contact Us</a></p>
    
  </div>
</template>

<script>
import CounterCards from './Counter.vue';
export default {
  name: 'HelloWorld',
  components: {
    CounterCards
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #ffffff;
}
</style>
