<template>
    <div class="flex flex-row justify-center items-center place-items-center ">

        <div class="w-3/4 sm:w-1/2  grid grid-cols-4 gap-1">
            <div>
                <p class="text-3xl h-8 sm:text-7xl sm:h-12 ">{{ days % 365 }}</p>
                <br/>
                <p class="text-xs sm:text-sm" >DAYS</p>
            </div>
            <div>
                <p class="text-3xl h-8 sm:text-7xl sm:h-12 ">{{ hours % 24 }}</p>
                <br/>
                <p class="text-xs sm:text-sm">HOURS</p>
            </div>
            <div>
                <p class="text-3xl h-8 sm:text-7xl sm:h-12 ">{{ minutes % 60 }}</p>
                <br/>
                <p  class="text-xs sm:text-sm">MINUTES</p>
            </div>
            <div>
                <p class="text-3xl h-8 sm:text-7xl sm:h-12 align-text-bottom">{{ seconds % 60 }}</p>
                <br/>
                <p  class="text-xs sm:text-sm">SECONDS</p>
            </div>
        </div>
    </div>
</template>


<script>
import { ref } from '@vue/reactivity';

export default {
    name: "CounterCards",
    setup() {
        const years = ref(0);
        const days = ref(0);
        const hours = ref(0);
        const minutes = ref(0);
        const seconds = ref(0);
        const lunchDate = new Date('24 February 2024');


        setInterval(() => {
            const currentDate = new Date();
            const lunchTime = lunchDate - currentDate;

            seconds.value = parseInt(lunchTime / 1000);
            minutes.value = parseInt(seconds.value / 60);
            hours.value = parseInt(minutes.value / 60);
            days.value = parseInt(hours.value / 24);
            years.value = parseInt(days.value / 365);

        }, 1000);

        return { years, days, hours, minutes, seconds }
    },

};
</script>



<style>
.opacitybg {
    background: rgba(255, 255, 255, 0.7);
}</style>