<template>
  <NavBar />
  <div class="flex justify-center items-center" >  
    <img src="./assets/logo.png" >
  </div>
  <HelloWorld />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import NavBar from "./components/NavBar.vue";

export default {
  name: 'App',
  components: {
    HelloWorld,
    NavBar
  }
}
</script>

<style>

body {
  background-image: url("./assets/bg.jpg");
  background-color: #501D71;
  font-family: 'Quicksand', sans-serif;
}

#app {
  text-align: center;
  color: #ffffff;
}
</style>
