<template>
  <nav class="navbar flex justify-end">

  </nav>
</template>

<script>

export default {
  name: 'NavBar',
};
</script>
